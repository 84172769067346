import React, { useEffect, useState } from 'react';

// Modules
import Reveal from 'reveal.js';

// App
import { filesystem_root } from '../../config';
import { getOnlineNode, getExternal } from '../../core/getNode';
import Slide from './partials/slide/view/slide';
import { jsonParser, endAnimation, addWidescreen, iframeClickEvents } from '../../utils/helpers';

// UI components
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { Box, Alert, CircularProgress } from '@mui/material';


export default function ViewPresentation(props) {
  const [isLoading, setLoading] = useState(true);
  const [isError, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('An error occurred. Please try again or contact system administrator.');

  // redux
  const [presentation, setPresentation] = useState(null);
  const [backgroundColor, setBackgroundColor] = useState('');
  const [reveal, setReveal] = useState(null);
  const [indexh, setIndexH] = useState(0);

  useEffect(() => {
    if (window.published || window.pdf ) {
      getContentFromStaticFS();
    } else {
      getContentFromApi();
    }
  }, [])

  const getContentFromApi = () => {
    let path = `${window.domain}/api/presentation/${window.id}?print=true&status=false`;

    getOnlineNode(path)
      .then((response) => {
        if (response.data) {
          setPresentation(response.data);

          const json = jsonParser(response.data.json);

          if (json?.backgroundColor) {
            setBackgroundColor(json.backgroundColor);
          }

          setError(false);
          setLoading(false);

          initReveal();
        } else {
          console.log('error');
          setError(true);
        }
      })
      .catch((_error) => {
        setLoading(false);
        setError(true);
      });
  };

  const getContentFromStaticFS = () => {
    let path = `${filesystem_root}/${window.subdomain}/presentation_book.php?presentation_id=${window.id}`;

    getExternal(path, 'get')
      .then((response) => {
        if (response.data) {
          renderPresentation(response);
        } else {
          setError(true);
          setLoading(false);
        }
      })
      .catch((_error) => {
        setLoading(false);
        setError(true);
      });
  };

  const renderPresentation = (response) => {
    setPresentation(response.data);
    const json = jsonParser(response.data.json);

    if (json?.backgroundColor) {
      setBackgroundColor(json.backgroundColor);
    }

    setError(false);
    setLoading(false);
    initReveal();
  };

  const initReveal = (index) => {
    let revealSettings = {
      width: 1366,
      height: 768,
      margin: 0,
      slideNumber: false,
      help: false,
      transition: 'slide',
      progress: false,
      hashOneBasedIndex: true,
      respondToHashChanges: true,
      hash: true,
      controls: false,
      controlsTutorial: false,
      overview: true,
      fragmentInURL: true,
      autoSlide: 0,
      preloadIframes: true,
    };

    let deck = new Reveal(revealSettings);
    window.Reveal = deck;

    deck.initialize()
      .then(() => {

        setReveal(deck);

        if (index) {
          deck.slide(index);
        }

        const indices = deck.getIndices();

        if (indices && indices.h) {
          setIndexH(indices.h);
        }

        const currentSlide = deck.getCurrentSlide();
        checkAdvancedSlide(currentSlide);

        deck.on('slidechanged', event => {
          setIndexH(event.indexh);
          // setIndexV(event.indexv);
          checkAdvancedSlide(event.currentSlide);
        });

        deck.on('slidetransitionend', event => {
          const allIframes = document.querySelectorAll('.slides iframe');
          allIframes.forEach(iframe => {
            addWidescreen(iframe);
          });
        });
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  const checkAdvancedSlide = (currentSlide) => {
    const allIframes = document.querySelectorAll('.slides iframe');
    allIframes.forEach(iframe => {
      addWidescreen(iframe);
    });

    const currentIframe = currentSlide.querySelector('iframe.advanced-slide');
    endAnimation(currentIframe);
    iframeClickEvents(currentIframe);
  };

  if (isLoading) {
    return (
      <div className='loading-container w-100 h-100 d-flex justify-content-center align-items-center'>
        <CircularProgress size={20} className={'m-0'} />
      </div>
    );
  } else {
    if (isError) {
      return (
        <Row className='h-100 justify-content-center align-self-center'>
          <Col>
            <Box className='d-flex h-100 justify-content-center align-items-center align-content-center' sx={{ flexDirection: 'column' }}>
              <Alert variant='filled' severity='error'>{errorMessage}</Alert>
            </Box>
          </Col>
        </Row>
      )
    } else {
      return (
        <main className='view-presentation screen'>
          <Container fluid className='ps-container' style={backgroundColor ? { backgroundColor: backgroundColor } : {}}>
            <Row className='ps-row justify-content-center align-self-center'>
              <Col className='ps-col p-0 ps-viewer' style={{position: 'relative'}}>
                  <div
                    className={`reveal ${props.preview ? 'preview' : ''}`}
                  >
                    <div className='slides'>
                      {presentation?.slides?.map((slide, index) => {
                        return (
                          <Slide
                            key={`slide-${index}`}
                            live={false}
                            presentation_id={presentation?.id}
                            themes={presentation.themes}
                            slide={slide}
                            activeTrigger={false}
                            playSlideAudio={false}
                            showSlideAudioControls={false}
                            hideSlideAudioControls={() => {}}
                            onSlideAudioPlaying={(value) => {}}
                            slideHasAudio={(value) => {}}
                            slideHasAudioPrompt={() => {}}
                            promptVisible={false}
                          />
                        )
                      })}
                    </div>
                  </div>
              </Col>
            </Row>
          </Container>
        </main>
      );
    }
  }
}