import React, {useState } from  'react';

// Modules
import Slider from 'react-slick';

// App
import { filesystem_root } from '../../../config';
import { alignment, animation, color } from '../../../core/editorOptions';
import { decodeHtmlEntities } from '../../../utils/helpers';

// UI components
import {ChevronLeft, ChevronRight} from 'react-feather';

function PrevArrow(props) {
  const {className, style, onClick} = props;

  return <ChevronLeft onClick={onClick} className={className} style={{style}}  />;
}

function NextArrow({className, style, onClick}) {
  return <ChevronRight onClick={onClick} className={className} style={{style}} />;
}

function Title({block}) {
  const blockColor = color(block, true);
  let styles = {};

  if (blockColor && blockColor.length > 0) {
    styles = {
      color: blockColor
    };
  }

  const text = decodeHtmlEntities(block?.data?.text);

  return <h1 className={`slide-title ${animation(block)} ${alignment(block)}`} style={styles}>{text}</h1>;
}

export default function ImageGallery(props) {
  const [activeSlide, setActiveSlide] = useState(0);
  const images = props.images;
  const title = props.title;

  const settings = {
    // dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "60px",
    variableWidth: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    afterChange: current => setActiveSlide(current)
  };

  return (
    <>
      {title && (
        <Title block={title} />
      )}
      <div>
        <span className='gallery-counter'>{activeSlide+1 + '/' + images.length}</span>
        <Slider {...settings} >
          {images.map((image, index) => {
            let url = image.url;

            if (props.live) {
              url = `${filesystem_root}/${window.subdomain}/presentations/${window.id}/${window.id}/${url}`;
            }

            return (
              <div key={`item-${index}`} className='gallery_image' style={{width: 768}}>
                <img src={url} data-mid={image.mid} alt={image.caption ? image.caption : ''} />
                {image.caption && (
                  <span className='caption'>{decodeHtmlEntities(image.caption)}</span>
                )}
              </div>
            );
          })}
        </Slider>
      </div>
    </>
  );
};
