import React, { Component } from 'react';
import {Routes, Route} from 'react-router-dom';

// Components
import ViewPresentation from '../screens/presentations/viewPresentation';

class RoutesComponent extends Component {
  render() {
    return (
      <Routes>
        <Route path="/" element={<ViewPresentation />} />
      </Routes>
    );
  }
}

export default RoutesComponent;
