import React, { useState } from 'react';

// Modules
import Slider from 'react-slick';

// App
import { alignment, animation, color } from '../../../core/editorOptions';
import { decodeHtmlEntities } from '../../../utils/helpers';
import ImageBlock from './bespokeGalleryImageBlock';

// function PrevArrow(props) {
//   const {className, style, onClick} = props;

//   return <ChevronLeft onClick={onClick} className={className} style={{style}}  />;
// }

// function NextArrow({className, style, onClick}) {
//   return <ChevronRight onClick={onClick} className={className} style={{style}} />;
// }

function Title({ block }) {
  const blockColor = color(block, true);
  let styles = {};

  if (blockColor && blockColor.length > 0) {
    styles = {
      color: blockColor
    };
  }

  const text = decodeHtmlEntities(block?.data?.text);

  return <h1 className={`slide-title ${animation(block)} ${alignment(block)}`} style={styles}>{text}</h1>;
}

export default function BespokeImageGallery(props) {
  const images = props.images;
  const title = props.title;


  // const ImageBlock = ({image, index}) => {
  //   let url = image.url;
  //   let [active, setActive] = useState(false);
  //   if (props.live) {
  //     url = `${filesystem_root}/${subdomain}/presentations/${props.presentation_id}/${props.presentation_id}/${url}`;
  //   }

  //   return (
  //     <div className={`image-container ${active ? 'active' : ''}`} id={`bsp-img-gal-${index}`}>
  //       <img className='image' src={url} data-mid={image.mid} alt={image.caption ? image.caption : ''} />
  //       {image.caption && (
  //         <div className='caption-container'>
  //           <span className='caption'>{decodeHtmlEntities(image.caption)}</span>
  //           <button
  //             className='caption-toggle'
  //             dangerouslySetInnerHTML={{ __html: AccordionArrow }}
  //             onClick={() => {
  //               let tempActive = active;
  //               setActive(!tempActive);
  //             }
  //             }
  //           />
  //         </div>
  //       )}
  //     </div>
  //   )
  // }


  return (
    <>
      {title && (
        <Title block={title} />
      )}
      <div className='artotel-image-gallery-container'>
        {images.map((e, i) => {
          return (
            <ImageBlock
              image={e}
              index={i + 1}
              live={props.live}
              presentation_id={props.presentation_id}
            />
          )
          // return imageBlock(e, i + 1)
        })}
      </div>
    </>
  );
};
