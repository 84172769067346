import { filesystem_root } from '../config';

export const backgroundImageURIGenerator = (image, isLive, slide, presentation_id) => {
  let uri = ''
  
  if (image) {
    if (typeof image === 'string') {
      uri = image;
    } else {
      if (image.x && image.y && image.width && image.height) {
        uri = `${window.domain}/api/image?mid=${image.mid}&x=${image.x}&y=${image.y}&width=${image.width}&height=${image.height}&maxWidth=${image.maxWidth}&maxHeight=${image.maxHeight}`
      } else {
        uri = image.field_media_image;
      }
    }
  }

  if (isLive) {
    const isLibrary = parseInt(window.id) === parseInt(slide.initial_presentation) ? false : true;

    if (isLibrary) {
      uri = `${filesystem_root}/${window.subdomain}/presentations/${slide.initial_presentation}/${slide.initial_presentation}/${uri}`;
    } else {
      uri = `${filesystem_root}/${window.subdomain}/presentations/${window.id}/${window.id}/${uri}`;
    }
  }

  return decodeURI(uri);
}