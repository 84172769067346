import React, { useState, useRef } from  'react';

// Modules
import Slider from 'react-slick';

// App
import { filesystem_root } from '../../../config';
import { decodeHtmlEntities } from '../../../utils/helpers';

// UI components
import {ChevronDown, ChevronUp} from 'react-feather';

function PrevArrow(props) {
  // const {className, style, onClick} = props;
  const {className, style, onClick} = props;

  return (
    <ChevronUp color='#fff' size={24} onClick={onClick} className={className} style={{style}} />
  );
}

function NextArrow({className, style, onClick}) {
  return (
    <ChevronDown onClick={onClick} className={className} style={{style}} color='#fff' size={24}  />
  );
}

export default function FullscreenImageGallery(props) {
  const [activeSlide, setActiveSlide] = useState(0);

  const mainSlider = useRef(null);

  const images = props.images;
  const settings = {
    adaptiveHeight: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: false,
    swipe: false,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    afterChange: (current) => {
      setActiveSlide(current);
    },
  };

  // <span className='gallery-counter'>{activeSlide+1 + '/' + images.length}</span>

  return (
    <div className='slider-container'>
      <span className='slider-counter'>{activeSlide + 1 + '/' + images.length}</span>
      <div className='slider'>
        <Slider ref={mainSlider} {...settings} >
          {images.map((image, index) => {
            let url = image.url;

            if (props.live) {
              url = `${filesystem_root}/${window.subdomain}/presentations/${window.id}/${window.id}/${url}`;
            }

            return (
              <div key={`item-${index}`} className='slide'>
                <div className='slide-inner-wrap'>
                  <img src={url} data-mid={image.mid} alt={image.caption ? image.caption : ''} />
                </div>
                {image?.caption?.length > 0 && (
                  <div className={`caption caption-${index} ${activeSlide === index ? 'animate' : ''}  `}>
                    <div className='caption-wrapper'>
                      {decodeHtmlEntities(image.caption)}
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </Slider>
      </div>
    </div>
  );
};
