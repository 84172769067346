import axios from 'axios';

const blogApi = axios.create({
  baseURL: 'https://companyapp.co.uk/',
  headers: {
    'Content-Type': 'application/json',
  },
});

export const getOnlineNode = (_endpoint, options) => {
  return axios({
    method: 'get',
    url: _endpoint,
    headers: {
      'Cache-Control': 'no-cache',
      ...options,
    },
  });
};

export const getExternal = (endpoint, method) => {
  return blogApi({
    method: method ? method : 'get',
    url: endpoint,
    withCredentials: false,
  });
};