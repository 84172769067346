import axios from 'axios';
axios.defaults.withCredentials = true;

export const filesystem_root = process.env.REACT_APP_FILES;
export const proxy = process.env.REACT_APP_FRAME_PROXY;

export const api = axios.create({
  withCredentials: true,
  timeout: 600000, // 30s
  headers: {
    'Content-Type': 'application/json',
  },
});
