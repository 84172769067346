import { decode } from "html-entities";

export const jsonParser = (_json) => {
  let json = {};

  try {
    json = JSON.parse(_json);

    if (json === null) {
      json = {};
    }
  } catch (error) {
    json = {};
  }

  return json;
};

export const decodeHtmlEntities = (label) => {
  let item = label;

  try {
    item = decode(item);
  } catch (error) {
    // console.log('Cannot decode categories');
    return label;
  }

  return item;
};

export const getFrameWindow = (iframe) => {
  let doc;

  if (iframe.contentWindow) {
    return iframe.contentWindow;
  }

  if (iframe.window) {
    return iframe.window;
  }

  if (!doc && iframe.contentDocument) {
    doc = iframe.contentDocument;
  }

  if (!doc && iframe.document) {
    doc = iframe.document;
  }

  if (doc && doc.defaultView) {
    return doc.defaultView;
  }

  if (doc && doc.parentWindow) {
    return doc.parentWindow;
  }

  return undefined;
};

export const runAnimation = (iframe) => {
  if (iframe) {
    const iframe_window = getFrameWindow(iframe);

    if (iframe_window) {
      const iframe_document = iframe.contentDocument
        ? iframe.contentDocument
        : iframe.contentWindow
        ? iframe.contentWindow.document
        : iframe.document;

      var interval;
      interval = setInterval(function () {
        if (
          iframe_document.readyState === 'complete' ||
          iframe_document.readyState === 'interactive'
        ) {
          clearInterval(interval);
          if (typeof iframe_window.runAnimation !== 'undefined') {
            iframe_window.runAnimation();
          }
        }
      }, 500);
    }
  }
};

export const addWidescreen = (iframe) => {
  if (iframe) {
    const iframe_window = getFrameWindow(iframe);

    if (iframe_window) {
      const iframe_document = iframe.contentDocument
        ? iframe.contentDocument
        : iframe.contentWindow
        ? iframe.contentWindow.document
        : iframe.document;

      var interval;
      interval = setInterval(function () {
        if (
          iframe_document.readyState === 'complete' ||
          iframe_document.readyState === 'interactive'
        ) {
          clearInterval(interval);
          iframe_window.document.body.classList.add('widescreen');
        }
      }, 500);
    }
  }
};

export const endAnimation = (iframe) => {
  if (iframe) {
    const iframe_window = getFrameWindow(iframe);

    if (iframe_window) {
      const iframe_document = iframe.contentDocument ? iframe.contentDocument : (iframe.contentWindow ? iframe.contentWindow.document : iframe.document);

      var interval;
      interval  = setInterval(function() {
        if( iframe_document.readyState === 'complete' || iframe_document.readyState === 'interactive' ) {
          clearInterval(interval);
          if( typeof iframe_window.endAnimation !== 'undefined' ) {
            iframe_window.endAnimation();
          } else {
            runAnimation(iframe);
          }
        }
      }, 500);
    }
  }
};


export const iframeClickEvents = (iframe) => {
  if (iframe) {
    const iframe_window = getFrameWindow(iframe);

    if (iframe_window) {
      const iframe_document = iframe.contentDocument
        ? iframe.contentDocument
        : iframe.contentWindow
        ? iframe.contentWindow.document
        : iframe.document;

      var interval;
      interval = setInterval(function () {
        if (
          iframe_document.readyState === 'complete' ||
          iframe_document.readyState === 'interactive'
        ) {
          clearInterval(interval);
          iframe_window.document.addEventListener('click', clickEvents, false);
        }
      }, 500);
    }
  }
};

export const clickEvents = (event) => {
  const link = event?.target?.closest('a');

  if (link && link.href) {
    const href = link.href.toLowerCase();

    if (href.indexOf('gotoslide') > -1 || href.indexOf('reloadslide') > -1 || href.indexOf('opendocument') > -1 || href.indexOf('openexternal') > -1 || href.indexOf('openViewerMenu') > -1) {
      event.stopPropagation();
      event.preventDefault();

      if (href.indexOf('openexternal') > -1) {
        let linkToOpen = href;
        linkToOpen = linkToOpen.split('link=');
        linkToOpen = linkToOpen[1];
        window.open(linkToOpen);
      }
    }

    return false;
  }
};

export const getThemeJson = (theme_label) => {
  let json = {};

  if (theme_label) {
    json = window.themes[theme_label];

    try {
      json = JSON.parse(json);
    } catch {
      // error
    }
  }

  return json;
};


export const getLayoutIcons = (layout_id, theme_label) => {
  let icons;
  const theme_json = getThemeJson(theme_label);

  theme_json?.layouts.forEach((layout) => {
    if (layout.id === layout_id) {
      icons = layout.icons;
    }
  });

  return icons;
};

export const getLayoutFiles = (layout_id, theme_label) => {
  let files;
  const theme_json = getThemeJson(theme_label);

  theme_json?.layouts.forEach((layout) => {
    if (layout.id === layout_id) {
      files = layout.files;
    }
  });

  return files;
};

export const getThemeBackgrounds = (theme_label) => {
  let colors = [];
  const theme_json = getThemeJson(theme_label);

  if (theme_json?.backgroundColors) {
    colors = theme_json.backgroundColors;
  }

  return colors;
};

export const getParamFromURL = (url, parameter) => {
  const urlParams = new URLSearchParams(url);
  const myParam = urlParams.get(parameter);

  return myParam;
};

export const processObject = (obj) => {
  for (let key in obj) {
    if (typeof obj[key] === "object" && obj[key] !== null) {
      // Recursively process nested objects
      obj[key] = processObject(obj[key]);
    } else if (typeof obj[key] === "string") {
      // Perform operations on string values
      obj[key] = decode_utf8(obj[key]);
    }
  }
  return obj;
};

/**
 * Deprecated use of escape
 */
export const decode_utf8 = (string) => {
  try {
    return decodeURIComponent(escape(string));
  } catch (e) {
    return string;
  }
};

export const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);